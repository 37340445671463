import Vue from 'vue'
import VueRouter from 'vue-router'
import Main from '@/components/Main.vue'
import Home from '@/components/Home.vue'
import More from '@/components/more.vue'
import Help  from '@/components/Help.vue'
import Search from '@/components/Search.vue'
import Animals from '@/components/Animals.vue'
import Plants from '@/components/Plants.vue'
import Microorganism from '@/components/Microorganism.vue'
import Blast from '@/components/Blast.vue'
import Orf from '@/components/Orf.vue'
import Homologous from '@/components/Homologous.vue'
import Phylogenetic from '@/components/Phylogenetic.vue'
import Function from '@/components/function.vue'
import DetailData from '@/components/DetailData.vue'

Vue.use(VueRouter)

const routes = [
  { path: '/main', component: Main ,
    redirect: 'home',
    children: [
      { path: '/home', component: Home },
      { path: '/more', component: More },
      { path: '/help', component: Help},
      { path: '/search', component: Search },
      { path: '/animals', component: Animals },
      { path: '/plants', component: Plants },
      { path: '/microorganism', component: Microorganism},
      { path: '/blast', component: Blast },
      { path: '/orf', component: Orf },
      { path: '/homologous', component: Homologous },
      { path: '/phylogenetic', component: Phylogenetic },
      { path: '/function', component: Function },
      { path: '/detailData', component: DetailData },

      
    ]
  },


  { path: '/', redirect: '/main' },
  
]

const router = new VueRouter({
  routes
})



export default router
