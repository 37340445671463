<template>
  <div class="animal-gallery">
    <h2>
      <div class="title">Animal</div>
    </h2>



    <div class="row1">
      <div
        v-for="(item, index) in filteredImgRow1"
        :key="index"
        class="pic_div"
      >
        <img
          :src="item.photo_url"
          alt=""
          :id="item.tax_id"
          @click="click_img(item.tax_id)"
        />
        <span>{{ item.org_name }}</span>
      </div>
    </div>
    <div class="row">
      <div
        v-for="(item, index) in filteredImgRow2"
        :key="index"
        class="pic_div"
      >
        <img
          :src="item.photo_url"
          alt=""
          :id="item.tax_id"
          @click="click_img(item.tax_id)"
        />
        <span>{{ item.org_name }}</span>
      </div>
    </div>
    <div class="row">
      <div
        v-for="(item, index) in filteredImgRow3"
        :key="index"
        class="pic_div"
      >
        <img
          :src="item.photo_url"
          alt=""
          :id="item.tax_id"
          @click="click_img(item.tax_id)"
        />
        <span>{{ item.org_name }}</span>
      </div>
    </div>

    <el-pagination
      background
      layout="prev, pager, next, jumper"
      :total="img_quest.total"
      :page-size="img_quest.pageSize"
      :current-page.sync="img_quest.page"
      @current-change="handlePageChange"
    />


    <ListData v-if="list_flag"></ListData>
    
  </div>
</template>

<script>
import ListData from "@/components/ListData.vue";

export default {
  components: { ListData },

  data() {
    return {
      img_data: [],
      img_row1: [],
      img_row2: [],
      img_row3: [],
      list_data: [],
      list_data_quest: {
        pageSize: 6,
        page: 1,
        total: 100,
        tax_id: 8022,
      },
      img_quest: {
        type: "animal",
        pageSize: 15,
        page: 1,
        total: 100,
      },
      list_flag: false,
    };
  },
  computed: {
    filteredImgRow1() {
      return this.img_row1.filter((item) => item.photo_url);
    },
    filteredImgRow2() {
      return this.img_row2.filter((item) => item.photo_url);
    },
    filteredImgRow3() {
      return this.img_row3.filter((item) => item.photo_url);
    },
  },
  methods: {
    handlePageChange() {
      this.get_img_data();
    },

    click_img(tax_id) {
      this.list_flag = false; // 先设置为 false，强制销毁 ListData 组件
      this.$nextTick(() => {
        this.list_flag = true; // 然后再设置为 true，触发 ListData 组件的重新渲染
      });


      sessionStorage.setItem("list_id", tax_id);
    },

    async get_img_data() {
      var demo = this;
      await this.$http
        .get("typedata", {
          params: {
            type: "animal",
            pageSize: 15,
            page: demo.img_quest.page,
          },
        })
        .then((res) => {
          demo.img_data = res.data.data;
          demo.img_quest.total = res.data.total;

          demo.img_row1 = [];
          demo.img_row2 = [];
          demo.img_row3 = [];

          for (let i = 0; i < demo.img_data.length; i++) {
            if (i < 5) {
              demo.img_row1.push(demo.img_data[i]);
            } else if (i < 10) {
              demo.img_row2.push(demo.img_data[i]);
            } else if (i < 15) {
              demo.img_row3.push(demo.img_data[i]);
            }
          }

          // 如果不足15个，补充空对象
          while (demo.img_row1.length < 5) {
            demo.img_row1.push({ photo_url: "", org_name: "", tax_id: "" });
          }
          while (demo.img_row2.length < 5) {
            demo.img_row2.push({ photo_url: "", org_name: "", tax_id: "" });
          }
          while (demo.img_row3.length < 5) {
            demo.img_row3.push({ photo_url: "", org_name: "", tax_id: "" });
          }
        });
    },


  },
  created() {
    this.get_img_data();
  },
};
</script>

<style scoped lang="less" >
.animal-gallery {
  text-align: center;
}
.row {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin: 60px 5px;
  span {
    font-size: 16px;
    font-weight: 500;
  }
}
.row1 {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin: 15px 5px;
  span {
    font-size: 16px;
    font-weight: 500;
  }
}
.pic_div {
  height: 210px;
  width: 210px;
  // border: 2px solid;
  margin: 5px;
  img {
    height: 100%;
  }
}
.title {
  margin: 2px auto;
  height: 50px;
  width: 180px;
  border-bottom: 5px solid;
}
.el-pagination {
  margin: 50px 10px;
}
a {
  cursor: pointer;
  color: rgb(31, 78, 121);
  text-decoration: none;
}

/* 修改表头样式 */
::v-deep .el-table__header-wrapper th {
  font-size: 17px; /* 表头字体大小 */
  background-color: rgb(242, 242, 242) !important; /* 表头背景颜色 */
  color: #333; /* 表头字体颜色 */
  font-weight: 600; /* 加粗字体 */
  text-align: center; /* 表头文字居中 */
  padding: 15px 8px; /* 内边距调整 */
}

/* 确保单元格内容居中 */
::v-deep .el-table__body-wrapper td {
  text-align: center; /* 单元格文字居中 */
  padding: 8px; /* 内边距调整 */
  color: black;
}
.species-list-div {
  text-align: center;
}

img{
  transition: all 0.5s;
}
img:hover{
  transform: scale(1.1); /* 让图片在悬停时放大 */
  // border: 2px solid;
}


</style>
