import Vue from 'vue'
import App from './App.vue'
import router from './router'
import './plugins/element.js'

import './assets/css/global.css'
import axios from 'axios'






// axios.defaults.baseURL = 'http://192.168.31.12:8000/api/'
// Vue.prototype.$staticBaseURL = 'http://192.168.31.12:8000/media/'
axios.defaults.baseURL = '/api'
Vue.prototype.$staticBaseURL = '/media'

// Vue.prototype.img_base_path = 'http://localhost:8066/img/'

Vue.prototype.$http = axios

Vue.config.productionTip = false



new Vue({
  router,
  render: h => h(App)
}).$mount('#app')
