<template>
  <div class="help-data">
    <el-card class="box-card">
      <div slot="header">        
        <span class="header-title"  >Browse according to species</span>
      </div>


     <chart1></chart1>
     <chart2></chart2>
     <chart3></chart3>
    


      
    </el-card>
  </div>
</template>



<script >

import chart1 from'@/components/Microorganism/Chart1.vue'
import chart2 from'@/components/Microorganism/Chart2.vue'
import chart3 from'@/components/Microorganism/Chart3.vue'

export default {

  components: { chart1,chart2,chart3},
  data() {
    return {
      list_flag: true,
    };
  }

}

</script>



<style scoped>
.help-data {
  max-width: 1580px;
  margin: auto;
  padding: 20px;
  background-color: #f5f5f5;
  border-radius: 8px;
}

.box-card {
  
  box-shadow: 0 4px 14px rgba(0, 0, 0, 0.1);
}
.el-card__header {
  background-color: rgb(31, 78, 121);
}
.header-title {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  background-color: rgb(31, 78, 121); /* 深蓝色背景 */
  color: white;
  font-size: 22px; /* 适当增加字体大小以匹配视觉效果 */
  font-weight: bold;
  padding: 10px 10px; /* 增加左右内边距 */
  width: 100%; /* 宽度设置为100% */
  box-sizing: border-box; /* 确保内边距和边框包含在宽度内 */
  margin-bottom: 15px;
}


</style>


