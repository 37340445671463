<template>
  <!-- contact-us 模块 -->
  <div class="contact-us">
    <p class="description">
      If you have any questions, comments or suggestions about our website,
      please feel free to contact us:
      <a href="mailto:gefei_hao@foxmail.com" class="email-link"
        >gefei_hao@foxmail.com</a
      >
    </p>

    <div class="form-title">Comments and suggestions are welcomed</div>

    <el-form
      :model="form"
      :rules="rules"
      ref="form"
      label-width="150px"
      class="contact-form"
    >
      <el-form-item label="Email address" prop="email">
        <el-input
          v-model="form.email"
          placeholder="name@example.com"
          clearable
        ></el-input>
      </el-form-item>

      <el-form-item label="Subject" prop="subject">
        <el-select
          v-model="form.subject"
          placeholder="Please select"
          class="subject-select"
        >
          <el-option label="Data download" value="Data download"></el-option>
          <el-option label="Website issue" value="Website issue"></el-option>
          <el-option
            label="General feedback"
            value="General feedback"
          ></el-option>
        </el-select>
      </el-form-item>

      <el-form-item label="Message" prop="message">
        <el-input
          type="textarea"
          v-model="form.message"
          placeholder="Your suggestions or comments"
          rows="5"
          clearable
        ></el-input>
      </el-form-item>

      <el-form-item class="form-buttons">
        <el-button @click="submitForm" class="submit-btn">Submit</el-button>
        <el-button @click="resetForm" class="reset-btn">Reset</el-button>
      </el-form-item>
    </el-form>
  </div>
</template>

<script>
export default {
  data() {
    return {
      form: {
        email: "",
        subject: "",
        message: "",
      },
      rules: {
        email: [
          {
            required: false,
            message: "Please enter a valid email address",
            trigger: "blur",
          },
        ],
        subject: [
          {
            required: false,
            message: "Please select a subject",
            trigger: "change",
          },
        ],
        message: [
          {
            required: true,
            message: "Message cannot be empty",
            trigger: "blur",
          },
        ],
      },
    };
  },
  methods: {
    async submitData() {
      await this.$http
        .post("suggestion/", {
          params: {
            email: this.form.email,
            subject: this.form.subject,
            message: this.form.message,
          },
        })
        .then((response) => {
          // console.log(response);
          if (response.data.result === "success") {
            this.$message({
              message: "反馈成功",
              type: "success",
            });
          }
        });
    },
    // 提交表单
    submitForm() {
      this.$refs.form.validate((valid) => {
        if (valid) {
          this.submitData();
        } else {
          console.log("Form is invalid.");
        }
      });
    },
    // 重置表单
    resetForm() {
      // 重置字段为初始状态
      this.$refs.form.resetFields();
    },
  },
};
</script>

<style scoped>
.contact-us {
  padding: 10px;
}

.box-card {
  box-shadow: 0 4px 14px rgba(0, 0, 0, 0.1);
}
.el-card__header {
  background-color: rgb(31, 78, 121);
}
.header-title {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  background-color: rgb(31, 78, 121); /* 深蓝色背景 */
  color: white;
  font-size: 22px; /* 适当增加字体大小以匹配视觉效果 */
  font-weight: bold;
  padding: 10px 10px; /* 增加左右内边距 */
  width: 100%; /* 宽度设置为100% */
  box-sizing: border-box; /* 确保内边距和边框包含在宽度内 */
  margin-bottom: 15px;
}

.description {
  font-size: 21px; /* Slightly reduced to match the larger input font size */
  margin-bottom: 25px;
  line-height: 1.6;
  color: #333;
  text-indent: 2em;
  font-weight: 500;
}

.email-link {
  color: black; /* 设置链接的颜色，这里使用亮蓝色 */
  text-decoration: none; /* 去除下划线 */
}

.email-link:hover,
.email-link:focus {
  color: #409eff;
  text-decoration: underline; /* 悬停或聚焦时添加下划线以提高可访问性 */
  outline: none; /* 移除聚焦时的轮廓线 */
}

.form-title {
  font-size: 24px;
  font-weight: bold;
  margin-bottom: 20px;
  color: #2c3e50;
}

.form-buttons {
  display: flex;
  justify-content: flex-end;
}

.submit-btn {
  background-color: rgb(31, 78, 121); /* 深蓝色背景 */
  color: white;
  font-size: 20px;
  font-weight: 600;
  margin-right: 30px;
}

.reset-btn {
  margin-right: 40px;
  background-color: rgb(31, 78, 121); /* 深蓝色背景 */
  color: white;
  font-size: 20px;
  font-weight: 600;
}

.submit-btn:hover,
.submit-btn:focus,
.submit-btn:active {
  background-color: rgb(31, 78, 121); /* 鼠标悬停和聚焦时保持背景色 */
  border-color: rgb(31, 78, 121); /* 鼠标悬停和聚焦时保持边框色 */
  color: white;
}
.reset-btn:hover,
.reset-btn:focus,
.reset-btn:active {
  background-color: rgb(31, 78, 121); /* 鼠标悬停和聚焦时保持背景色 */
  border-color: rgb(31, 78, 121); /* 鼠标悬停和聚焦时保持边框色 */
  color: white;
}

.contact-form .el-form-item {
  margin-bottom: 25px; /* 统一底部外边距以保持一致的间距 */
}

/* 使输入框和选择框宽度统一 */
.contact-form .el-input,
.contact-form .el-select {
  width: 100%; /* 设置宽度为100%，保证填满其容器 */
}
</style>
