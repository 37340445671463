<template>
  <div class="help-data">
    <el-card class="box-card">
      <div slot="header">
        <span class="header-title" @click="about_btn" v-if="about_flag"
          >▼ About us</span
        >
        <span class="header-title" @click="about_btn" v-else>▶ About us</span>
      </div>
      <div slot="header">
        <span
          class="header-title"
          @click="instructions_btn"
          v-if="instructions_flag"
          >▼ Database access instructions</span
        >
        <span class="header-title" @click="instructions_btn" v-else
          >▶ Database access instructions</span
        >
      </div>
      <div slot="header">
        <span class="header-title" @click="contact_btn" v-if="contact_flag"
          >▼Contact us</span
        >
        <span class="header-title" @click="contact_btn" v-else
          >▶ Contact us</span
        >
      </div>

      <aboutUs v-if="about_flag"></aboutUs>
      <instructions v-if="instructions_flag"></instructions>
      <contactUs v-if="contact_flag"></contactUs>
    </el-card>
  </div>
</template>

<script>
import contactUs from "./Help/ContactUs.vue";
import instructions from "./Help/Instructions.vue";
import aboutUs from "./Help/AboutUs.vue";

export default {
  components: { contactUs, aboutUs, instructions },
  data() {
    return {
      about_flag: true,
      instructions_flag: false,
      contact_flag: false,
    };
  },
  methods: {
    about_btn() {
      this.about_flag = !this.about_flag;
      // 只展示一个
      if (this.about_flag === true) {
        this.instructions_flag = false;
        this.contact_flag = false;
      }
    },
    instructions_btn() {
      this.instructions_flag = !this.instructions_flag;
      // 只展示一个
      if (this.instructions_flag === true) {
        this.about_flag = false;
        this.contact_flag = false;
      }
    },
    contact_btn() {
      this.contact_flag = !this.contact_flag;

      // 只展示一个
      if (this.contact_flag === true) {
        this.about_flag = false;
        this.instructions_flag = false;
      }
    },
  },
};
</script>

<style scoped>
.help-data {
  max-width: 1580px;
  margin: auto;
  padding: 20px;
  background-color: #f5f5f5;
  border-radius: 8px;
}

.box-card {
  box-shadow: 0 4px 14px rgba(0, 0, 0, 0.1);
}
.el-card__header {
  background-color: rgb(31, 78, 121);
}
.header-title {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  background-color: rgb(31, 78, 121); /* 深蓝色背景 */
  color: white;
  font-size: 22px; /* 适当增加字体大小以匹配视觉效果 */
  font-weight: bold;
  padding: 10px 10px; /* 增加左右内边距 */
  width: 100%; /* 宽度设置为100% */
  box-sizing: border-box; /* 确保内边距和边框包含在宽度内 */
  margin-bottom: 15px;
}
</style>


