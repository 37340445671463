<template>
  <div class="home_div">
    <el-card class="box-card">
      <div slot="header">
        <span class="header-title"
          >Search according to general information and properties</span
        >
      </div>

      <!--单选部分-->
      <div>
        <h1 class="search-type">1. General Search</h1>
        <span style="font-size: 20px">
          Please search the related list and get the detailed set information.
          Note: one per line
        </span>
      </div>

      <!-- 使用el-row和el-col布局选择框和文本框 -->
      <el-row class="search-box-container" type="flex" justify="start">
        <el-col :span="7">
          <el-select
            v-model="data_type"
            clearable
            placeholder="Data Types"
            class="search-box"
          >
            <el-option
              v-for="item in options"
              :key="item.value"
              :label="item.label"
              :value="item.label"
            >
            </el-option>
          </el-select>
        </el-col>

        <el-col :span="17">
          <el-input
            type="textarea"
            :rows="5"
            v-model="inputValue"
            :placeholder="inputPlaceholder"
            class="input-box"
          />
          <el-row type="flex" justify="end" style="margin-top: 10px">
            <el-button
              type="warning"
              style="
                margin-left: 10px;
                background-color: #806000;
                border-color: #806000;
              "
              @click="clear1"
              >CLEAR</el-button
            >
            <el-button
              type="primary"
              style="
                margin-left: 30px;
                background-color: #385724;
                border-color: #385724;
              "
              @click="search1"
              >SEARCH</el-button
            >
          </el-row>
        </el-col>
      </el-row>

      <br />
      <br />

      <!--多选部分-->

      <div>
        <h1 class="search-type">2. Advanced Search</h1>
        <span style="font-size: 20px">
          You can input two keywords to search. The two keywords coule be
          connected by the `AND` or `OR` operators.
        </span>
      </div>

      <!-- 使用el-row和el-col布局选择框和文本框 -->
      <el-row class="search-box-container" type="flex" justify="start">
        <el-col :span="7">
          <el-select
            v-model="data_type1"
            clearable
            placeholder="Data Types"
            class="search-box"
          >
            <el-option
              v-for="item in options"
              :key="item.value"
              :label="item.label"
              :value="item.label"
            >
            </el-option>
          </el-select>

          <div style="margin: 0 25px">
            <el-radio v-model="method" label="and">AND</el-radio>
            <el-radio v-model="method" label="or">OR</el-radio>
          </div>

          <el-select
            v-model="data_type2"
            clearable
            placeholder="Data Types"
            class="search-box"
          >
            <el-option
              v-for="item in options"
              :key="item.value"
              :label="item.label"
              :value="item.label"
            >
            </el-option>
          </el-select>
        </el-col>

        <el-col :span="17">
          <el-input
            v-model="inputValue1"
            :placeholder="inputPlaceholder1"
            class="input-box underline-input"
          />
          <el-input
            v-model="inputValue2"
            :placeholder="inputPlaceholder2"
            class="input-box underline-input"
          />

          <el-row type="flex" justify="end" style="margin-top: 10px">
            <el-button
              type="warning"
              style="
                margin-left: 10px;
                background-color: #806000;
                border-color: #806000;
              "
              @click="clear2"
              >CLEAR</el-button
            >
            <el-button
              type="primary"
              style="
                margin-left: 30px;
                background-color: #385724;
                border-color: #385724;
              "
              @click="search2"
              >SEARCH</el-button
            >
          </el-row>
        </el-col>
      </el-row>

      <div v-if="list_flag" style="margin-top: 40px" ref="results">
        <span class="header-title">Search results</span>
        <ListData :searchParams="searchParams"></ListData>
      </div>
    </el-card>
  </div>
</template>

<script>
import ListData from "@/components/SearchListData.vue";

export default {
  components: { ListData },
  data() {
    return {
      options: [
        { label: "MAGEdb ID" },
        { label: "NCBI gene ID" },
        { label: "Gene name" },
        { label: "Protein name" },
        { label: "Organism" },
        { label: "Taxonomy ID" },
        { label: "Plant Growth" },
        { label: "Stress name" },
        { label: "Disease name" },
        { label: "Stage of life cycle" },
        { label: "Pathway component" },
      ],
      data_type: "", // 选中的类别
      data_type1: "",
      data_type2: "",
      inputValue: "", // 输入框的值
      inputValue1: "",
      inputValue2: "",
      inputPlaceholder: "Please enter specific content", // 输入框的示例文本
      inputPlaceholder1: "Please enter specific content",
      inputPlaceholder2: "Please enter specific content",
      method: "",
      list_flag: false,
      searchParams: {},
    };
  },
  watch: {
    // 监听 data_type、data_type1 和 data_type2 的变化
    data_type(newVal) {
      this.updatePlaceholder(newVal, "inputPlaceholder");
    },
    data_type1(newVal) {
      this.updatePlaceholder(newVal, "inputPlaceholder1");
    },
    data_type2(newVal) {
      this.updatePlaceholder(newVal, "inputPlaceholder2");
    },
  },
  methods: {
    // 通用的更新方法
    updatePlaceholder(newVal, placeholderKey) {
      let placeholderText = "";

      if (newVal === "MAGEdb ID") {
        // 根据输入框的类型决定要显示的占位符内容
        if (placeholderKey === "inputPlaceholder") {
          placeholderText = "MAGE00001\nMAGE00002\nMAGE00003"; // 多行
        } else {
          placeholderText = "MAGE00001"; // 单行
        }
      } else if (newVal === "NCBI gene ID") {
        if (placeholderKey === "inputPlaceholder") {
          placeholderText = "176\n249\n283"; // 多行
        } else {
          placeholderText = "176"; // 单行
        }
      } else if (newVal === "Gene name") {
        if (placeholderKey === "inputPlaceholder") {
          placeholderText = "ACAN\nALPL\nANG"; // 多行
        } else {
          placeholderText = "ACAN"; // 单行
        }
      } else if (newVal === "Protein name") {
        if (placeholderKey === "inputPlaceholder") {
          placeholderText =
            "aggrecan\nangiogenin\nkallikrein related peptidase 3"; // 多行
        } else {
          placeholderText = "aggrecan"; // 单行
        }
      } else if (newVal === "Organism") {
        if (placeholderKey === "inputPlaceholder") {
          placeholderText =
            "Homo sapiens (Human)\nMus musculus (Mouse)\nRattus norvegicus (Rat)"; // 多行
        } else {
          placeholderText = "Homo sapiens (Human)"; // 单行
        }
      } else if (newVal === "Taxonomy ID") {
        if (placeholderKey === "inputPlaceholder") {
          placeholderText = "9606\n10090\n10116"; // 多行
        } else {
          placeholderText = "9606"; // 单行
        }
      } else if (newVal === "Plant Growth") {
        if (placeholderKey === "inputPlaceholder") {
          placeholderText = "Flower\nFruit\nLeaf"; // 多行
        } else {
          placeholderText = "Flower"; // 单行
        }
      } else if (newVal === "Stress name") {
        if (placeholderKey === "inputPlaceholder") {
          placeholderText = "Bacterial stress\nCold stress\nDrought stress"; // 多行
        } else {
          placeholderText = "Bacterial stress"; // 单行
        }
      } else if (newVal === "Disease name") {
        if (placeholderKey === "inputPlaceholder") {
          placeholderText =
            "Acute liver injury\nAcute reflux esophagitis\nAge-related macular degeneration"; // 多行
        } else {
          placeholderText = "Acute liver injury"; // 单行
        }
      } else if (newVal === "Stage of life cycle") {
        if (placeholderKey === "inputPlaceholder") {
          placeholderText = "Aging\nGrowth\nReproduction"; // 多行
        } else {
          placeholderText = "Aging"; // 单行
        }
      } else if (newVal === "Pathway component") {
        if (placeholderKey === "inputPlaceholder") {
          placeholderText =
            "Biosynthesis genes\nIntracellular receptors\nMembrane receptors and ligands"; // 多行
        } else {
          placeholderText = "Biosynthesis genes"; // 单行
        }
      } else {
        placeholderText = "Please enter specific content"; // 默认提示
      }

      this[placeholderKey] = placeholderText; // 动态设置占位符内容
    },

    clear1() {
      this.inputValue = "";
    },

    clear2() {
      this.inputValue1 = "";
      this.inputValue2 = "";
    },

    search1() {
      this.searchParams = {
        searchType1: this.data_type,
        value1: this.inputValue,
      };
      this.list_flag = true;
      this.scrollToResults();
    },

    search2() {
      this.searchParams = {
        searchType1: this.data_type1,
        value1: this.inputValue1,
        searchType2: this.data_type2,
        value2: this.inputValue2,
        method: this.method,
      };
      this.list_flag = true;
      this.scrollToResults();
    },

    scrollToResults() {
      this.$nextTick(() => {
        const resultsElement = this.$refs.results;
        if (resultsElement) {
          resultsElement.scrollIntoView({ behavior: "smooth" });
        }
      });
    },
  },
};
</script>

<style scoped>
.home_div {
  max-width: 1580px;

  padding: 20px;
  background-color: #f5f5f5;
  border-radius: 8px;
}

.box-card {
  box-shadow: 0 4px 14px rgba(0, 0, 0, 0.1);
}

.el-card__header {
  background-color: rgb(31, 78, 121);
}

.header-title {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  background-color: rgb(31, 78, 121); /* 深蓝色背景 */
  color: white;
  font-size: 22px; /* 适当增加字体大小以匹配视觉效果 */
  font-weight: bold;
  padding: 10px 10px; /* 增加左右内边距 */
  width: 100%; /* 宽度设置为100% */
  box-sizing: border-box; /* 确保内边距和边框包含在宽度内 */
  margin-bottom: 15px;
}

.search-type {
  text-align: left;
  margin: 5px 0;
  font-size: 24px;
}

.search-box {
  margin: 20px;
}

/* 设置el-select的圆角 */
::v-deep .search-box .el-input__inner {
  border-radius: 10px; /* 设置圆角并确保生效 */
  border: 3px solid #385724;
}

::v-deep .search-box .el-input__inner:hover {
  border-color: #385724; /* 保持绿色边框 */
}

::v-deep .search-box .el-input__inner:focus {
  border-color: #385724 !important; /* 强制保持绿色边框 */
}

/* 控制布局 */
.search-box-container {
  display: flex;
  gap: 10px; /* 设置选择框和文本框之间的间隔 */
}

/* 输入框样式 */
.input-box {
  border-radius: 10px; /* 设置圆角 */
  margin: 20px 0; /* 增加右边距，将右边距调整为 40px */
}

/* 控制输入框的绿色边框 */
::v-deep .input-box .el-textarea__inner {
  border: 3px solid #385724 !important; /* 设置绿色边框 */
}

/* 取消输入框悬停和点击时的边框变化 */
::v-deep .input-box .el-textarea__inner:hover,
::v-deep .input-box .el-textarea__innerr:focus {
  border-color: #385724 !important; /* 强制保持绿色边框 */
}

.el-col:last-child {
  margin-right: 40px; /* 增加右边距 */
}

::v-deep .el-radio.is-checked .el-radio__inner {
  background-color: #385724; /* 设置背景色为绿色 */
  border-color: #385724; /* 设置边框颜色为绿色 */
}

/* 防止字体颜色变化 */
::v-deep .el-radio.is-checked .el-radio__label {
  color: inherit; /* 保持字体颜色不变 */
}

::v-deep .underline-input .el-input__inner {
  border: none !important; /* 去掉默认的边框 */
  border-bottom: 3px solid #385724 !important; /* 添加下划线 */
  border-radius: 0 !important; /* 去掉圆角 */
  padding: 5px 10px !important; /* 调整内边距 */
  margin: 8px 15px 8px 20px;
}

/* 聚焦时的下划线颜色变化 */
::v-deep .underline-input .el-input__inner:focus {
  outline: none !important; /* 去掉焦点时的外部轮廓 */
  border-bottom: 3px solid #385724 !important; /* 聚焦时下划线颜色变化 */
}
</style>
