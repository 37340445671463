<template>
  <div>
    <div id="echart3" style="width: 1500px; height: 800px"></div>
    <ListDataByName v-if="list_flag" :key="componentKey"></ListDataByName>
  </div>
</template>





<script>
import * as echarts from 'echarts'
import ListDataByName from "@/components/ListDataByOtherName.vue";
export default {
  components: { ListDataByName},
  data() {
    return {
      componentKey: 0, // 新增
      list_flag: false,
      echarts_value: [],
      echarts_category: [],
      search_data: {
        career: '学生',
        end_time: '',
        start_time: '',
      }
    };
  },
  mounted() {
    this.initEcharts();
  },
  methods: {
    initEcharts() {
      var myChart = echarts.init(document.getElementById('echart3'));

      var option = {
        tooltip: {
          trigger: 'item',
          textStyle: {
            fontSize: 15,
            fontFamily: 'Arial'
          }
        },

        legend: {
          type: 'scroll',
          orient: 'vertical',
          x: '45%',
          y: '45%',
          align: 'left',
          icon: 'circle',
          height: '65%',
          textStyle: {
            fontSize: 14,
            fontFamily: 'Arial'
          }
        },

        series: [
          {
            name: 'Others',
            type: 'pie',
            radius: ['70%', '30%'],
            center: ['25%', '50%'],
            avoidLabelOverlap: false,
            itemStyle: {
              borderRadius: 10,
              borderColor: '#fff',
              borderWidth: 2
            },

            label: {
              formatter: 'Others',
              position: 'center',
              fontSize: 40,
              fontFamily: 'Arial',
              fontWeight: 'bold'
            },

            data: [
              {
                value: 1,
                name: 'Methanotorris igneus (strain DSM 5666 / JCM 11834 / Kol 5)'
              },
              { value: 2, name: 'Alfamovirus AMV' },
              { value: 1, name: 'Pyrococcus abyssi (strain GE5 / Orsay)' },
              { value: 3, name: 'Dictyostelium discoideum AX4' }
            ]
          }
        ]
      };

      // 使用刚指定的配置项和数据显示图表。
      myChart.setOption(option);
            // 添加图例点击事件监听
      var demo=this;
      myChart.on("legendselectchanged", function (params) {
        // console.log("Selected legend name:", params.name);
        demo.list_flag=true;
        sessionStorage.setItem("other_name", params.name);
        demo.componentKey += 1; // 改变key值强制刷新组件

      });
    },

  },
  created() {

  }
}
</script>