<template>
  <div>
    <div class="text_box">
      <p>
        The melatonin-related pathway consists of receptors (including membrane
        receptors and intracellular receptors), signaling molecules,
        transporters, transcription factors, responsive genes, melatonin
        synthesis genes, and melatonin metabolism genes. Click on the different
        modules below to get the related genes in different categories.
      </p>
    </div>

    <div class="img_box">
      <img src="IR_img" alt="" />
      <img
        class="bg_img"
        src="@/assets/imgs/pathway/animals/background.jpeg"
        alt=""
      />
      <img
        @click="click_img('Intracellular receptors')"
        class="IR_img"
        src="@/assets/imgs/pathway/animals/IR.png"
        alt=""
      />
      <img
        @click="click_img('Metabolic genes')"
        class="MG_img"
        src="@/assets/imgs/pathway/animals/MG.png"
        alt=""
      />
      <img
        @click="click_img('Membrane receptors and ligands')"
        class="MR_img"
        src="@/assets/imgs/pathway/animals/MR.png"
        alt=""
      />
      <img
        @click="click_img('Transporters and signals')"
        class="TS_img"
        src="@/assets/imgs/pathway/animals/TS.png"
        alt=""
      />
      <img
        @click="click_img('Signal transduction and responsive genes')"
        class="SR_img"
        src="@/assets/imgs/pathway/animals/SR.png"
        alt=""
      />
      <img
        @click="click_img('Transcription factors')"
        class="TF_img"
        src="@/assets/imgs/pathway/animals/TF.png"
        alt=""
      />
      <img
        @click="click_img('Biosynthesis genes')"
        class="BG_img"
        src="@/assets/imgs/pathway/animals/BG.png"
        alt=""
      />
    </div>

    <ListData v-if="list_flag"></ListData>
  </div>
</template>  



<script>
import ListData from "@/components/ListDataByPathway.vue";

export default {
  components: { ListData },

  data() {
    return {
      list_flag: false,
    };
  },

  methods: {
    click_img(val) {
      this.list_flag = false; // 先设置为 false，强制销毁 ListData 组件
      this.$nextTick(() => {
        this.list_flag = true; // 然后再设置为 true，触发 ListData 组件的重新渲染
      });

      sessionStorage.setItem("pathway", val);
      sessionStorage.setItem("species_type", "Animal");
    },
  },
  created() {},
};
</script>





<style lang="less"  scoped>
.text_box {
  padding: 8px;
  margin: 0 auto;
  background-color: #f9f9f9;
  border: 1px solid #ddd;
  border-radius: 10px;
}
p {
  font-size: 21px;
  color: black;
  margin-bottom: 5px;
  font-weight: 400;
}
.img_box {
  position: relative;
}
.bg_img {
  width: 100%;
}
.IR_img {
  position: absolute;
  top: 1068px;
  left: 244px;
  width: 454px;
}
.MG_img {
  position: absolute;
  top: 525px;
  left: 1172px;
  width: 325px;
}
.MR_img {
  position: absolute;
  top: 26px;
  left: 52px;
  width: 764px;
}
.TS_img {
  position: absolute;
  top: 27px;
  left: 866px;
  width: 600px;
}
.SR_img {
  position: absolute;
  top: 592px;
  left: 67px;
  width: 676px;
}
.TF_img {
  position: absolute;
  top: 920px;
  left: 715px;
  width: 226px;
}
.BG_img {
  position: absolute;
  top: 518px;
  left: 823px;
  width: 245px;
}
</style>