<template>
  <div>
    <div id="viewport" ref="viewport"></div>

    <div class="entry-container">
      <!-- 左侧静态 Entry -->
      <div class="entry-label">Entry</div>

      <!-- 右侧动态下拉菜单 -->
      <div class="entry-dropdown">
        <el-select
          @change="selectEntry"
          v-model="value"
          placeholder="请选择"
          class="custom-select"
        >
          <el-option
            v-for="item in options_data"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          />
        </el-select>
      </div>
    </div>
  </div>
</template>

<script>
import * as NGL from "ngl";

export default {
  data() {
    return {
      magdb_id: "",
      options_data: [],
      value: "",
      pdb_url: "",
      stage: null, // Add the stage here to keep track of the NGL stage
    };
  },

  mounted() {
    this.get_PDB_data();  // Fetch the data on mount
  },

  methods: {
    // Initialize or reset the PDB viewer only after the PDB URL is set
    async initPDB() {
      if (!this.pdb_url) return; // Ensure the URL is not empty

      // If stage already exists, remove the previous viewer
      if (this.stage) {
        this.stage.removeAllComponents(); // Clear the previous scene
      } else {
        this.stage = new NGL.Stage(this.$refs.viewport);
        this.stage.setParameters({ backgroundColor: "white" });

        // Resize listener for viewport resizing
        window.addEventListener("resize", () => {
          this.stage.handleResize();
        });
      }

      try {
        // Load the new PDB file
        await this.stage.loadFile(this.pdb_url, { defaultRepresentation: true });
      } catch (error) {
        console.error("Error loading file:", error);
      }
    },

    // Fetch the PDB data from API
    async get_PDB_data() {
      this.magdb_id = sessionStorage.getItem("magdb_id");

      if (!this.magdb_id) {
        console.error("No magdb_id found");
        return;
      }

      try {
        const response = await this.$http.get("genedetail", {
          params: { magdb_id: this.magdb_id },
      
        });
        const arr = response.data.response.alphafold_url;
        const arr2 = response.data.response.pdb_url;
 
         for(var i=0;i<arr2.length;i++){
             arr.push(arr2[i])
         }

  


         
        // Map data into options for the dropdown
        this.options_data = arr.map(item => ({
          value: item[1],
          label: item[0],
        }));

        // Automatically set the first value to the dropdown if it exists
        if (this.options_data.length > 0) {
          this.value = this.options_data[0].value;
          this.pdb_url = this.value;  // Set the pdb_url to the selected value
          this.initPDB(); // Initialize PDB viewer with selected URL
        }      } catch (error) {
        console.error("Error fetching PDB data:", error);
      }
    },

    // When an entry is selected from the dropdown
    selectEntry(val) {
      console.log("Selected entry:", val);
      this.pdb_url = val;
      this.initPDB(); // Reinitialize the PDB viewer when the URL changes
    },
  },

  watch: {
    // Watch the value of the dropdown to ensure PDB URL updates correctly
    value(newVal) {
      this.pdb_url = newVal; // Update the pdb_url when the dropdown value changes
      this.initPDB(); // Reinitialize the PDB viewer
    },
  },
};
</script>

<style scoped lang="less">
#viewport {
  border: 5px solid #7f7f7f;
  width: 1560px;
  height: 500px;
  margin: 20px auto;
  border-radius: 6px;
}

/* 外部容器 */
.entry-container {
  display: flex;
  align-items: center;
  border: 4px solid #7f7f7f;
  border-radius: 6px; /* 增加边框圆角，视觉更美观 */
  width: 500px; /* 原宽度 400px，放大 1.5 倍 */
  height: 60px; /* 原高度 40px，放大 1.5 倍 */
  font-family: Arial, sans-serif;
  background-color: #fff;
  margin-left: 10px;
}

/* 左侧 Entry 样式 */
.entry-label {
  flex: 1;
  text-align: center;
  font-size: 26px; /* 原字体大小 14px，放大 1.5 倍 */
  font-weight: bold;
  display: flex;
  justify-content: center;
  align-items: center;

  height: 60px;
  border-right: 4px solid #7f7f7f;
}

/* 右侧下拉框 */
.entry-dropdown {
  flex: 3;
  display: flex;
  align-items: center;
  padding: 0 10px; /* 原 padding 10px，放大 1.5 倍 */
  font-size: 28px !important;
  font-weight: bold;
}

/* 自定义 El-Select 样式 */
::v-deep(.custom-select .el-input__inner) {
  border: none; /* 去掉内部边框 */
  box-shadow: none; /* 去掉阴影 */
  font-size: 24px; /* 原字体大小 14px，放大 1.5 倍 */
  color: #000;
  height: 57px; /* 原高度 38px，放大 1.5 倍 */
  padding: 0 10px; /* 增加内部边距，视觉更平衡 */
}

::v-deep(.custom-select .el-input__inner:focus) {
  border: none; /* 去掉聚焦时的边框 */
  box-shadow: none; /* 去掉聚焦时的阴影 */
}

/* 下拉菜单样式 */
::v-deep(.el-select-dropdown) {
  border: 1px solid #ccc;
  border-radius: 6px; /* 增加边框圆角 */
  box-shadow: 0px 6px 12px rgba(0, 0, 0, 0.1); /* 放大阴影效果 */
}

::v-deep(.el-select-dropdown__item:hover) {
  background-color: #f0f0f0;
}
</style>
