<template>
  <div class="animal-gallery">
    <!-- Gallery Rows -->
    <div class="row1">
      <div v-for="item in filteredImgRow1" :key="item" class="pic_div">
        <img :src="item" alt="" @click="zoomImage(item)" />
      </div>
    </div>
    <div class="row">
      <div v-for="item in filteredImgRow2" :key="item" class="pic_div">
        <img :src="item" alt="" @click="zoomImage(item)" />
      </div>
    </div>
    <div class="row">
      <div v-for="item in filteredImgRow3" :key="item" class="pic_div">
        <img :src="item" alt="" @click="zoomImage(item)" />
      </div>
    </div>

    <!-- Pagination -->
    <el-pagination
      background
      layout="prev, pager, next, jumper"
      :total="img_quest.total"
      :page-size="img_quest.pageSize"
      :current-page.sync="img_quest.page"
      @current-change="handlePageChange"
    />

    <!-- Modal for Enlarged Image -->
    <div v-if="isModalOpen" class="modal" @click="closeModal">
      <div class="modal-content">
        <img :src="zoomedImg" alt="Enlarged Image" />
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      img_data: [],
      img_row1: [],
      img_row2: [],
      img_row3: [],
      img_quest: {
        magdb_id: "",
        pageSize: 12,
        page: 1,
        total: 100,
      },
      isModalOpen: false,  // Track modal visibility
      zoomedImg: null,     // Store the enlarged image URL
    };
  },
  computed: {
    filteredImgRow1() {
      return this.img_row1.filter((item) => item);
    },
    filteredImgRow2() {
      return this.img_row2.filter((item) => item);
    },
    filteredImgRow3() {
      return this.img_row3.filter((item) => item);
    },
  },
  methods: {
    handlePageChange() {
      this.get_img_data();
    },

    click_img(tax_id) {
      // This function can be used if you want additional handling when an image is clicked.
    },

    zoomImage(img) {
      this.zoomedImg = img;   // Set the clicked image to the modal
      this.isModalOpen = true;  // Open the modal
    },

    closeModal() {
      this.isModalOpen = false; // Close the modal
    },

    async get_img_data() {
      console.log(this.img_quest);
      var demo = this;
      await this.$http
        .get("pathway", {
          params: {
            magdb_id: demo.img_quest.magdb_id,
            pageSize: 12,
            page: demo.img_quest.page,
          },
        })
        .then((res) => {
          demo.img_data = res.data.data;
          demo.img_quest.total = res.data.total;

          demo.img_row1 = [];
          demo.img_row2 = [];
          demo.img_row3 = [];

          for (let i = 0; i < demo.img_data.length; i++) {
            if (i < 4) {
              demo.img_row1.push(demo.img_data[i]);
            } else if (i < 8) {
              demo.img_row2.push(demo.img_data[i]);
            } else if (i < 12) {
              demo.img_row3.push(demo.img_data[i]);
            }
          }

          // If there are not enough images, fill with null objects
          while (demo.img_row1.length < 4) {
            demo.img_row1.push(null);
          }
          while (demo.img_row2.length < 4) {
            demo.img_row2.push(null);
          }
          while (demo.img_row3.length < 4) {
            demo.img_row3.push(null);
          }
        });
    },
  },
  created() {
    this.img_quest.magdb_id = sessionStorage.getItem("magdb_id");
    this.get_img_data();
  },
};
</script>
<style scoped lang="less">
.animal-gallery {
  text-align: center;
}

.row, .row1 {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  margin: 15px 5px;
}

.pic_div {
  height: 300px;
  width: 300px;
  margin: 5px;
  img {
    height: 100%;
    width: 100%;
    cursor: pointer;
    transition: transform 0.3s ease;
  }

  img:hover {
    transform: scale(1.05); /* Optional: smooth zoom effect on hover */
  }
}

.el-pagination {
  margin: 50px 10px;
}

.modal {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.7);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.modal-content {
  background-color: white;
  padding: 20px;
}

.modal img {
  max-width: 95%;
  max-height: 95vh;
}



</style>
