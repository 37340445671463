<template>
  <div>
    <div id="echart2" style="width: 1500px; height: 800px"></div>
     <ListDataByName v-if="list_flag" :key="componentKey"></ListDataByName>
    
 
  </div>
</template>





<script>
import * as echarts from 'echarts'
import ListDataByName from "@/components/ListDataByFungiName.vue";
export default {
  components: { ListDataByName},
  data() {
    return {
      list_flag: false,
      componentKey: 0, // 新增
      echarts_value: [],
      echarts_category: [],
      search_data: {
        career: '学生',
        end_time: '',
        start_time: '',
      }
    };
  },
  mounted() {
    this.initEcharts();
  },
  methods: {
    initEcharts() {
      var myChart = echarts.init(document.getElementById('echart2'));
      var option = {
            tooltip: {
              trigger: 'item',
              textStyle:{
                fontSize:15,
                fontFamily:'Arial',

              }
            },
            
            legend: {
              type: 'scroll',
              orient: 'vertical',
              x:'45%',
              y:'15%',
              align:'left',
              icon:'circle',
              height:'70%',
              textStyle:{
                fontSize:12,
                fontFamily:'Arial'
              }
            },

            series: [
              {
                name: 'Fungi',
                type: 'pie',
                radius: ['70%', '30%'],
                center:['25%','50%'],
                avoidLabelOverlap: false,
                itemStyle: {
                  borderRadius: 10,
                  borderColor: '#fff',
                  borderWidth: 2
                },
                
                label: {
                  formatter:'Fungi',
                  position:'center',
                  fontSize:40,
                  fontFamily:'Arial',
                  fontWeight:'bold'
                },

                data: [
                  { value: 26, name: 'Saccharomyces cerevisiae (strain ATCC 204508 / S288c) (Baker\'s yeast)'},
                  { value: 4, name: 'Candida glabrata (Yeast) (Torulopsis glabrata)' },
                  { value: 6, name: 'Agaricus bisporus var. bisporus H97' },
                  { value: 4, name: 'Cordyceps militaris (strain CM01) (Caterpillar fungus)' },
                  { value: 4, name: 'Saccharomyces cerevisiae (Baker\'s yeast)' },
                  { value: 2, name: 'Alternaria arborescens' },
                  { value: 2, name: 'Kwoniella shandongensis' },
                  { value: 1, name: 'Schizosaccharomyces pombe' },
                  { value: 2, name: '[Candida] haemuli' },
                  { value: 1, name: 'Emericella nidulans (strain FGSC A4 / ATCC 38163 / CBS 112.46 / NRRL 194 / M139) (Aspergillus nidulans)' },
                  { value: 1, name: 'Pyricularia oryzae (strain 70-15 / ATCC MYA-4617 / FGSC 8958) (Rice blast fungus) (Magnaporthe oryzae)' },
                  { value: 1, name: 'Eremothecium gossypii (strain ATCC 10895 / CBS 109.51 / FGSC 9923 / NRRL Y-1056) (Yeast) (Ashbya gossypii)' },
                  { value: 1, name: 'Aspergillus fumigatus (strain ATCC MYA-4609 / CBS 101355 / FGSC A1100 / Af293) (Neosartorya fumigata)' },
                  { value: 1, name: 'Neosartorya fischeri (strain ATCC 1020 / DSM 3700 / CBS 544.65 / FGSC A1164 / JCM 1740 / NRRL 181 / WB 181) (Aspergillus fischerianus)' },
                  { value: 1, name: 'Botryotinia fuckeliana (strain B05.10) (Noble rot fungus) (Botrytis cinerea)' },
                  { value: 1, name: 'Streptomyces alanosinicus' },
                  { value: 1, name: 'Gibberella moniliformis (strain M3125 / FGSC 7600) (Maize ear and stalk rot fungus) (Fusarium verticillioides)' },
                  { value: 1, name: 'Zymoseptoria tritici (strain CBS 115943 / IPO323) (Speckled leaf blotch fungus) (Septoria tritici)' },
                  { value: 1, name: 'Aspergillus terreus (strain NIH 2624 / FGSC A1156)' },
                  { value: 1, name: 'Aspergillus clavatus (strain ATCC 1007 / CBS 513.65 / DSM 816 / NCTC 3887 / NRRL 1 / QM 1276 / 107)' },
                  { value: 1, name: 'Neurospora crassa (strain ATCC 24698 / 74-OR23-1A / CBS 708.71 / DSM 1257 / FGSC 987)' },
                  { value: 1, name: '[Candida] pseudohaemuli' },
                  { value: 3, name: 'Aspergillus niger (strain ATCC MYA-4892 / CBS 513.88 / FGSC A1513)' },
                  { value: 1, name: 'Pyrenophora tritici-repentis (strain Pt-1C-BFP) (Wheat tan spot fungus) (Drechslera tritici-repentis)' },
                  { value: 1, name: 'Fusarium oxysporum f. sp. lycopersici (strain 4287 / CBS 123668 / FGSC 9935 / NRRL 34936) (Fusarium vascular wilt of tomato)' },
                  { value: 1, name: 'Hypocrea jecorina (strain QM6a) (Trichoderma reesei)' },
                  { value: 1, name: 'Verticillium dahliae (strain VdLs.17 / ATCC MYA-4575 / FGSC 10137) (Verticillium wilt)' },
                  { value: 1, name: 'Gaeumannomyces tritici (strain R3-111a-1) (Wheat and barley take-all root rot fungus) (Gaeumannomyces graminis var. tritici)' },
                  { value: 1, name: 'Colletotrichum graminicola (strain M1.001 / M2 / FGSC 10212) (Maize anthracnose fungus) (Glomerella graminicola)' },
                  { value: 1, name: 'Beauveria bassiana (strain ARSEF 2860) (White muscardine disease fungus) (Tritirachium shiotae)' },
                  { value: 1, name: 'Fusarium vanettenii (strain ATCC MYA-4622 / CBS 123669 / FGSC 9596 / NRRL 45880 / 77-13-4) (Fusarium solani subsp. pisi)' },
                  { value: 1, name: 'Leptosphaeria maculans (strain JN3 / isolate v23.1.3 / race Av1-4-5-6-7-8) (Blackleg fungus) (Phoma lingam)' },
                  { value: 2, name: '[Candida] duobushaemuli' },
                  { value: 1, name: 'Gibberella fujikuroi (strain CBS 195.34 / IMI 58289 / NRRL A-6831) (Bakanae and foot rot disease fungus) (Fusarium fujikuroi)' },
                  { value: 15, name: 'Saccharomyces boulardii (nom. inval.)' },
                  { value: 10, name: 'Saccharomyces pastorianus (Lager yeast) (Saccharomyces cerevisiae x Saccharomyces eubayanus)' },
                  { value: 16, name: 'Saccharomyces cerevisiae (strain YJM789) (Baker\'s yeast)' },
                  { value: 16, name: 'Saccharomyces cerevisiae (strain RM11-1a) (Baker\'s yeast)' },
                  { value: 5, name: 'Saccharomyces cerevisiae (strain AWRI1631) (Baker\'s yeast)' },
                  { value: 14, name: 'Saccharomyces cerevisiae (strain JAY291) (Baker\'s yeast)' },
                  { value: 13, name: 'Saccharomyces cerevisiae (strain Lalvin EC1118 / Prise de mousse) (Baker\'s yeast)' },
                  { value: 18, name: 'Saccharomyces cerevisiae (strain Kyokai no. 7 / NBRC 101557) (Baker\'s yeast)' },
                  { value: 11, name: 'Saccharomyces cerevisiae x Saccharomyces kudriavzevii (strain VIN7) (Yeast)' },
                  { value: 22, name: 'Saccharomyces cerevisiae (strain CEN.PK113-7D) (Baker\'s yeast)' },
                  { value: 1, name: 'Saccharomyces cerevisiae var. ellipsoideus)' },
                  { value: 1, name: 'Rhizoctonia solani' },
                  { value: 3, name: 'Saccharomyces paradoxus (Yeast) (Saccharomyces douglasii)' },
                  { value: 1, name: 'Alternaria alternata (Alternaria rot fungus) (Torula alternata)' },
                  { value: 1, name: 'Alternaria tenuissima' },
                  { value: 1, name: 'Cordyceps militaris (Caterpillar fungus) (Clavaria militaris)' },
                  { value: 4, name: 'Agaricus bisporus var. burnettii' },
                  { value: 3, name: 'Agaricus bisporus var. burnettii (strain JB137-S8 / ATCC MYA-4627 / FGSC 10392) (White button mushroom)' },
                  { value: 1, name: 'Fusarium oxysporum (strain Fo5176) (Fusarium vascular wilt)' },
                  { value: 1, name: 'Gibberella fujikuroi (Bakanae and foot rot disease fungus) (Fusarium fujikuroi)' },
                  { value: 1, name: 'Fusarium proliferatum (strain ET1) (Orchid endophyte fungus)' },
                  { value: 1, name: 'Fusarium oxysporum f. sp. radicis-cucumerinum' },
                  { value: 1, name: 'Fusarium oxysporum (Fusarium vascular wilt)' },
                  { value: 1, name: 'Gibberella intermedia (Bulb rot disease fungus) (Fusarium proliferatum)' },
                  { value: 1, name: 'Fusarium oxysporum f. sp. cepae' },
                  { value: 1, name: 'Fusarium oxysporum f. sp. narcissi' },
                  { value: 1, name: 'Fusarium oxysporum f. sp. cubense' },
                  { value: 1, name: 'Fusarium acutatum' },
                  { value: 1, name: 'Fusarium napiforme' },
                  { value: 1, name: 'Fusarium phyllophilum' },
                  { value: 1, name: 'Fusarium tjaetaba' },
                  { value: 1, name: 'Fusarium pseudoanthophilum' },
                  { value: 1, name: 'Fusarium oxysporum f. sp. conglutinans' },
                  { value: 1, name: 'Fusarium oxysporum f. sp. raphani' },
                  { value: 1, name: 'Fusarium agapanthi' },
                  { value: 1, name: 'Fusarium xylarioides' },
                  { value: 1, name: 'Fusarium redolens' },
                  { value: 1, name: 'Fusarium pseudograminearum (strain CS3096) (Wheat and barley crown-rot fungus)' },
                  { value: 1, name: 'Fusarium oxysporum f. sp. cubense (strain race 4) (Panama disease fungus)' },
                  { value: 1, name: 'Fusarium oxysporum f. sp. cubense (strain race 1) (Panama disease fungus)' },
                  { value: 1, name: 'Fusarium oxysporum NRRL 32931' },
                  { value: 1, name: 'Fusarium oxysporum Fo47' },
                  { value: 1, name: 'Fusarium mangiferae (Mango malformation disease fungus)' },
                  { value: 1, name: 'Gibberella nygamai (Bean root rot disease fungus) (Fusarium nygamai)' },
                  { value: 1, name: 'Fusarium pseudocircinatum' },
                  { value: 1, name: 'Fusarium sp. NRRL 25303' },
                  { value: 1, name: 'Fusarium denticulatum' },
                  { value: 1, name: 'Fusarium oxysporum f. sp. rapae' },
                  { value: 1, name: 'Fusarium musae' },
                  { value: 2, name: 'Aspergillus niger' },
                  { value: 2, name: 'Aspergillus lacticoffeatus (strain CBS 101883)' },
                  { value: 2, name: 'Aspergillus niger ATCC 13496' },
                  { value: 2, name: 'Aspergillus phoenicis ATCC 13157' },
                  { value: 1, name: 'Aspergillus welwitschiae' },
                  { value: 1, name: 'Aspergillus awamori (Black koji mold)' },
                  { value: 1, name: 'Aspergillus niger (strain ATCC 1015 / CBS 113.46 / FGSC A1144 / LSHB Ac4 / NCTC 3858a / NRRL 328 / USDA 3528.7)' },
                  { value: 1, name: '[Candida] haemuli var. vulneris' },
                  { value: 1, name: 'Aspergillus oryzae (Yellow koji mold)' },
                  { value: 1, name: 'Aspergillus flavus (strain ATCC MYA-384 / AF70)' },
                  { value: 1, name: 'Aspergillus flavus' },
                  { value: 1, name: 'Aspergillus flavus (strain ATCC 200026 / FGSC A1120 / IAM 13836 / NRRL 3357 / JCM 12722 / SRRC 167)i' },
                  { value: 1, name: 'Aspergillus oryzae (strain 3.042) (Yellow koji mold)' },
                  { value: 1, name: 'Aspergillus fumigatus (Neosartorya fumigata)' },
                  { value: 1, name: 'Aspergillus fumigatus (strain CBS 144.89 / FGSC A1163 / CEA10) (Neosartorya fumigata)' },
                  { value: 1, name: 'Zymoseptoria tritici (strain ST99CH_3D7)' },
                  { value: 1, name: 'Zymoseptoria tritici ST99CH_1A5' },
                  { value: 1, name: 'Zymoseptoria tritici ST99CH_1E4' },
                  { value: 1, name: 'Botryotinia fuckeliana (strain T4) (Noble rot fungus) (Botrytis cinerea)' },
                  { value: 1, name: 'Botryotinia fuckeliana (strain BcDW1) (Noble rot fungus) (Botrytis cinerea)' },
                  { value: 1, name: 'Hypocrea virens (strain Gv29-8 / FGSC 10586) (Gliocladium virens) (Trichoderma virens)' },
                  { value: 1, name: 'Schizosaccharomyces osmophilus' },
                  { value: 1, name: 'Schizosaccharomyces octosporus (strain yFS286) (Fission yeast) (Octosporomyces octosporus)' },
                  { value: 1, name: 'Schizosaccharomyces cryophilus (strain OY26 / ATCC MYA-4695 / CBS 11777 / NBRC 106824 / NRRL Y48691) (Fission yeast)' },
                  { value: 1, name: 'Fusarium oxysporum f. sp. melonis 26406' },
                  { value: 1, name: 'Candida glabrata (strain ATCC 2001 / BCRC 20586 / JCM 3761 / NBRC 0622 / NRRL Y-65 / CBS 138) (Yeast) (Nakaseomyces glabratus)' },
                  { value: 1, name: 'Pyricularia oryzae (Rice blast fungus) (Magnaporthe oryzae)' },
                  { value: 2, name: 'Phaeosphaeria nodorum (strain SN15 / ATCC MYA-4574 / FGSC 10173) (Glume blotch fungus) (Parastagonospora nodorum)' },
                  { value: 1, name: 'Pyrenophora tritici-repentis' },
                  { value: 1, name: 'Plenodomus tracheiphilus IPT5' },
                  { value: 1, name: 'Neurospora hispaniola' },
                ]
              }
            ]
          };
      // 使用刚指定的配置项和数据显示图表。
      myChart.setOption(option);
            // 添加图例点击事件监听
      var demo=this;
      myChart.on("legendselectchanged", function (params) {
        // console.log("Selected legend name:", params.name);
        demo.list_flag=true;
        sessionStorage.setItem("fungi_name", params.name);
        demo.componentKey += 1; // 改变key值强制刷新组件

      });
    },

  },
  created() {
    
  }
}
</script>