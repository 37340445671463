<template>
  <div>
    <div id="echart1" style="width: 1580px; height: 600px; "></div>
  </div>
</template>

<script>
import * as echarts from "echarts";

export default {
  props: {
    table_data: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      x_val: [],
      y_val: [],
    };
  },
  mounted() {
    this.initEcharts();
  },
  methods: {
    initEcharts() {
      const myChart = echarts.init(document.getElementById("echart1"));

      const option = {
        grid: {
  left: "2%",
  right: "10%",
  bottom: "5%", // 增加底部空间
  containLabel: true, // 防止标签超出绘图区
},
        xAxis: {
          type: "category",
          data: this.x_val,
          axisLabel: {
            rotate: 45, // 让标签旋转 45 度（可以调整为 90 实现完全竖直）
            interval: 0, // 显示所有标签
          },
          name: "Samples",
        },
        yAxis: {
          type: "value",
          name: "MRKM",
        },
        series: [
          {
            data: this.y_val,
            type: "bar",
             itemStyle: {
      color: " #2c539e", // 设置柱子的颜色
    },
          },
        ],
        tooltip: {
          trigger: "axis",
        },
      };

      // 使用刚指定的配置项和数据显示图表。
      myChart.setOption(option);
    },
  },
  created() {
    // 解析 table_data 对象，将数据分解为 x_val 和 y_val
    let i = 0;
    for (let [key, value] of Object.entries(this.table_data)) {
      if (i > 0) {
        this.x_val.push(key);
        this.y_val.push(Number(value)); // 转换为数值（如果是字符串类型）
      }
      i += 1;
    }
    // console.log("X轴数据：", this.x_val);
    // console.log("Y轴数据：", this.y_val);
  },
};
</script>
