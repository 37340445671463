<template>
  <div class="help-data">
    <el-card class="box-card">
      <div slot="header">        
        <span class="header-title" @click="species_btn" v-if="species_flag">▼ Browse according to species</span>
        <span class="header-title" @click="species_btn" v-else>▶ Browse according to species </span>

      </div>
      <div slot="header">
        <span class="header-title" @click="pathway_btn" v-if="pathway_flag">▼ Browse according to pathway</span>
        <span class="header-title" @click="pathway_btn" v-else>▶ Browse according to pathway</span>
      </div>
      <div slot="header">
        <span class="header-title" @click="function_btn" v-if="function_flag">▼ Browse according to function </span>
        <span class="header-title" @click="function_btn" v-else>▶ Browse according to function </span>
      </div>

      <species v-if="species_flag"></species>
      <pathway v-if="pathway_flag"></pathway>
      <functions v-if="function_flag"></functions>


    </el-card>
  </div>
</template>

<script>
import species from '@/components/Plants/Species.vue';
import pathway from '@/components/Plants/pathway.vue';
import functions from '@/components/Plants/function.vue';



export default {
  components: { functions,species,pathway},
  data() {
    return {
       species_flag:true,
       pathway_flag:false,
       function_flag:false,
    }
  },
  methods: {
     species_btn(){
      this.species_flag=!this.species_flag;
      // 只展示一个
      if(this.species_flag===true) {
        this.pathway_flag=false;
        this.function_flag=false;
      }

     },
     pathway_btn(){
      this.pathway_flag=!this.pathway_flag;
      // 只展示一个
      if(this.pathway_flag===true) {
        this.species_flag=false;
        this.function_flag=false;
      }

     },
     function_btn(){
      this.function_flag=!this.function_flag;


      // 只展示一个
      if(this.function_flag===true) {
      this.species_flag=false;
      this.pathway_flag=false;
      }
     }
  }
}
</script>

<style scoped>
.help-data {
  max-width: 1580px;
  margin: auto;
  padding: 20px;
  background-color: #f5f5f5;
  border-radius: 8px;
}

.box-card {
  
  box-shadow: 0 4px 14px rgba(0, 0, 0, 0.1);
}
.el-card__header {
  background-color: rgb(31, 78, 121);
}
.header-title {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  background-color: rgb(31, 78, 121); /* 深蓝色背景 */
  color: white;
  font-size: 22px; /* 适当增加字体大小以匹配视觉效果 */
  font-weight: bold;
  padding: 10px 10px; /* 增加左右内边距 */
  width: 100%; /* 宽度设置为100% */
  box-sizing: border-box; /* 确保内边距和边框包含在宽度内 */
  margin-bottom: 15px;
}


</style>


