<template>
  <div class="data-interface">
    <el-card class="box-card">
      <div slot="header" class="header-title">Download</div>
      <div class="download-section">
        <p class="description">
          All genes with related information:
          <a :href="gene_information_url" class="link">Download</a>
        </p>
        <p class="description">
          The sequence of all genes in FASTA format:
          <a :href="sequence_url" class="link">Download</a>
        </p>
        <p class="description">
          Homologous identification result:
          <a :href="orthology_url" class="link">Download</a>
        </p>
      </div>
    </el-card>

    <el-card class="box-card">
      <div slot="header" class="header-title">
        Welcome to submit new data to MAGEdb
      </div>
      <el-form :model="form" :rules="rules" ref="form" label-width="150px">
        <el-form-item label="Species" prop="species">
          <el-input
            v-model="form.species"
            placeholder="Species name"
          ></el-input>
        </el-form-item>
        <el-form-item label="Gene name" prop="geneName">
          <el-input v-model="form.geneName" placeholder="Gene name"></el-input>
        </el-form-item>
        <el-form-item label="Sequence" prop="sequence">
          <el-input
            type="textarea"
            rows="5"
            v-model="form.sequence"
            placeholder="Sequence"
          ></el-input>
        </el-form-item>
        <el-form-item label="Reference" prop="reference">
          <el-input
            v-model="form.reference"
            placeholder="DOI or PubMed ID"
          ></el-input>
        </el-form-item>
        <el-form-item label="External link" prop="externalLink">
          <el-input
            v-model="form.externalLink"
            placeholder="Gene IDs like NCBI gene id, Uniprot ID, Ensembl ID, etc. (optional)"
          ></el-input>
        </el-form-item>
        <el-form-item label="Email address" prop="email">
          <el-input
            v-model="form.email"
            placeholder="name@example.com (optional)"
          ></el-input>
        </el-form-item>
        <el-form-item class="form-buttons">
          <el-button @click="submitForm" class="submit-btn">Submit</el-button>
          <el-button @click="resetForm" class="reset-btn">Reset</el-button>
        </el-form-item>
      </el-form>
    </el-card>

    <!-- 上传版本二 -->
    <el-card class="box-card">
      <div slot="header" class="header-title">Upload data</div>
      <div
        class="upload-container"
        @drop.prevent="handleDrop"
        @dragover.prevent
      >
        <p>Drag your file here or <br /><em>click to browse...</em></p>
        <input type="file" id="file-input" @change="handleFileChange" />

        <!-- 显示选中的文件信息 -->
        <div v-if="file" class="file-info">
          <p><strong>Selected File:</strong> {{ file.name }}</p>
          <p><strong>File Size:</strong> {{ formatFileSize(file.size) }}</p>
        </div>
      </div>
      <el-button @click="uploadFile" class="upload-btn">Upload</el-button>
    </el-card>
  </div>
</template>

<script>
export default {
  name: "GeneDataForm",
  data() {
    return {
      gene_information_url:
        this.$staticBaseURL + "download/Gene_information.xlsx",
      sequence_url: this.$staticBaseURL + "download/sequence.zip",
      orthology_url: this.$staticBaseURL + "download/Orthology_result.xlsx",
      form: {
        species: "",
        geneName: "",
        sequence: "",
        reference: "",
        externalLink: "",
        email: "",
      },
      rules: {
        species: [
          {
            required: true,
            message: "Species is required",
            trigger: "blur",
          },
        ],
        geneName: [
          { required: true, message: "Gene name is required", trigger: "blur" },
        ],
        sequence: [
          { required: true, message: "Sequence is required", trigger: "blur" },
        ],
      },
      file: null,
    };
  },
  methods: {
    async submitData() {
      await this.$http
        .post("newdata/", {
          params: {
            species: this.form.species,
            gene_name: this.form.geneName,
            sequence: this.form.sequence,
            reference: this.form.reference,
            external_link: this.form.externalLink,
            email: this.form.email,
          },
        })
        .then((response) => {
          // console.log(response);
          if (response.data.result === "success") {
            this.$message({
              message: "success",
              type: "success",
            });
          }
        });
    },

    submitForm() {
      this.$refs.form.validate((valid) => {
        if (valid) {
          // console.log("Form data:", this.form);
          this.submitData();
        } else {
          console.error("Validation failed.");
        }
      });
    },

    resetForm() {
      this.$refs.form.resetFields();
    },

    handleFileChange(event) {
      this.file = event.target.files[0]; // 获取用户选择的第一个文件
    },

    handleDrop(event) {
      // 处理拖拽文件
      const files = event.dataTransfer.files;
      if (files.length > 0) {
        this.file = files[0]; // 获取拖拽的第一个文件
        console.log(this.file); // 调试文件对象
      }
    },

    // 格式化文件大小（例如：KB, MB）
    formatFileSize(size) {
      if (size < 1024) {
        return size + " bytes";
      } else if (size < 1048576) {
        return (size / 1024).toFixed(2) + " KB";
      } else {
        return (size / 1048576).toFixed(2) + " MB";
      }
    },

    uploadFile() {
      if (!this.file) {
        alert("Please select a file first！");
        return;
      }

      const formData = new FormData();
      formData.append("file", this.file); // 将文件添加到 FormData

      // 使用 axios 发送 POST 请求到后端
      this.$http
        .post("upload/", formData, {
          headers: {
            "Content-Type": "multipart/form-data", // 设置请求头，表示上传的是文件
          },
        })
        .then((response) => {
          if (response.data.result === "success") {
            this.$message({
              message: "success",
              type: "success",
            });
          }
        })
        .catch((error) => {
          console.error("error", error);
        });
    },
  },
};
</script>

<style scoped>
.data-interface {
  max-width: 1580px;
  margin: auto;
  padding: 20px;
  background-color: #f5f5f5;
  border-radius: 8px;
}

.box-card {
  box-shadow: 0 4px 14px rgba(0, 0, 0, 0.1);
}

.header-title {
  background-color: rgb(31, 78, 121);
  color: white;
  padding: 10px;
  font-size: 22px;
  font-weight: bold;
  text-transform: none !important;
}

.link {
  color: rgb(31, 78, 121);
  /* text-decoration: none; */
  font-weight: 600;
}

.link:hover {
  text-decoration: underline;
}

.form-buttons {
  display: flex;
  justify-content: space-between;
}
.description {
  font-size: 21px; /* Slightly reduced to match the larger input font size */
  margin-bottom: 25px;
  line-height: 1.6;
  color: #333;
  text-indent: 2em;
  font-weight: 500;
}
.el-form-item__label {
  font-size: 28px;
}

.form-buttons {
  display: flex;
  justify-content: flex-end;
}
.submit-btn {
  background-color: rgb(31, 78, 121); /* 深蓝色背景 */
  color: white;
  font-size: 20px;
  font-weight: 600;
  margin-right: 30px;
}

.reset-btn {
  margin-right: 40px;
  background-color: rgb(31, 78, 121); /* 深蓝色背景 */
  color: white;
  font-size: 20px;
  font-weight: 600;
}

.submit-btn:hover,
.submit-btn:focus,
.submit-btn:active {
  background-color: rgb(31, 78, 121); /* 鼠标悬停和聚焦时保持背景色 */
  border-color: rgb(31, 78, 121); /* 鼠标悬停和聚焦时保持边框色 */
  color: white;
}
.reset-btn:hover,
.reset-btn:focus,
.reset-btn:active {
  background-color: rgb(31, 78, 121); /* 鼠标悬停和聚焦时保持背景色 */
  border-color: rgb(31, 78, 121); /* 鼠标悬停和聚焦时保持边框色 */
  color: white;
}

.upload-btn {
  margin-left: 700px;
  margin-top: 20px;
  background-color: rgb(31, 78, 121); /* 深蓝色背景 */
  color: white;
  font-size: 20px;
  font-weight: 600;
}

.upload-btn:hover,
.upload-btn:focus,
.upload-btn:active {
  background-color: rgb(31, 78, 121); /* 鼠标悬停和聚焦时保持背景色 */
  border-color: rgb(31, 78, 121); /* 鼠标悬停和聚焦时保持边框色 */
  color: white;
}

.upload-container {
  width: 70%;
  margin: auto;
  padding: 20px;
  border: 2px dashed #ccc;
  border-radius: 5px;
  text-align: center;
  background-color: #f9f9f9;
}
.upload-container:hover {
  background-color: #f0f0f0;
}
.upload-container input[type="file"] {
  width: 100%;
  height: 100px;
  cursor: pointer;
  opacity: 0;
}
.upload-container button {
  margin-top: 20px;
  padding: 10px 20px;
  font-size: 16px;
  color: white;
  background-color: #007bff;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}
.upload-container button:hover {
  background-color: #0056b3;
}
.file-info {
  margin-top: 10px;
  font-size: 14px;
  color: #333;
  text-align: left;
  background-color: #e9f7fd;
  padding: 10px;
  border-radius: 5px;
}
</style>