<template>
  <div>
    <div id="echart1" style="width: 1500px; height: 800px"></div>
    <ListDataByName v-if="list_flag" :key="componentKey"></ListDataByName>
    
  </div>
</template>





<script>
import * as echarts from "echarts";
import ListDataByName from "@/components/ListDataByBacteriaName.vue";
export default {
    components: { ListDataByName},
  data() {
    return {
      componentKey: 0, // 新增
      list_flag: false,
      echarts_value: [],
      echarts_category: [],
      search_data: {
        career: "学生",
        end_time: "",
        start_time: "",
      },
    };
  },
  mounted() {
    this.initEcharts();
  },
  methods: {
    initEcharts() {
      var myChart = echarts.init(document.getElementById("echart1"));
      var option = {
        tooltip: {
          trigger: "item",
          textStyle: {
            fontSize: 15,
            fontFamily: "Arial",
          },
        },

        legend: {
          type: "scroll",
          orient: "vertical",
          x: "45%",
          y: "18%",
          align: "left",
          icon: "circle",
          height: "65%",
          textStyle: {
            fontSize: 12,
            fontFamily: "Arial",
          },
        },

        series: [
          {
            name: "Bacteria",
            type: "pie",
            radius: ["70%", "30%"],
            center: ["25%", "50%"],
            avoidLabelOverlap: false,
            itemStyle: {
              borderRadius: 10,
              borderColor: "#fff",
              borderWidth: 2,
            },
            label: {
              formatter: "bacteria",
              position: "center",
              fontSize: 40,
              fontFamily: "Arial",
              fontWeight: "bold",
            },

            data: [
              {
                value: 1,
                name: "Pseudomonas aeruginosa (strain ATCC 15692 / DSM 22644 / CIP 104116 / JCM 14847 / LMG 12228 / 1C / PRS 101 / PAO1)",
              },
              { value: 1, name: "Oceanithermus profundus DSM 14977" },
              { value: 1, name: "Intrasporangium calvum DSM 43043" },
              { value: 1, name: "Sphingobacterium sp. 21" },
              { value: 1, name: "Actinoplanes missouriensis 431" },
              { value: 1, name: "Rhizobium phaseoli" },
              { value: 1, name: "Bradyrhizobium japonicum" },
              { value: 3, name: "Xanthomonas oryzae pv. oryzicola" },
              { value: 2, name: "Pseudomonas fluorescens" },
              { value: 1, name: "Agrobacterium tumefaciens" },
              { value: 1, name: "Bacillus amyloliquefaciens" },
              { value: 1, name: "Bacillus cereus" },
              { value: 1, name: "Streptomyces pilosus" },
              { value: 1, name: "Streptomyces lavendofoliae" },
              { value: 1, name: "Streptomyces filipinensis" },
              { value: 1, name: "Streptomyces alanosinicus" },
              {
                value: 1,
                name: "Xanthomonas oryzae pv. oryzae (strain PXO99A)",
              },
              { value: 1, name: "Xanthomonas oryzae" },
              { value: 1, name: "Xanthomonas sp. Leaf148" },
              { value: 1, name: "Xanthomonas oryzae pv. oryzae" },
              { value: 1, name: "Xanthomonas floridensis" },
              { value: 1, name: "Xanthomonas prunicola" },
              { value: 1, name: "Xanthomonas codiaei" },
              { value: 1, name: "Xanthomonas pisi" },
              { value: 1, name: "Xanthomonas oryzae pv. oryzae" },
              { value: 1, name: "Xanthomonas sp. 3793" },
              { value: 2, name: "Xanthomonas oryzae pv. leersiae" },
              {
                value: 1,
                name: "Xanthomonas oryzae pv. oryzicola (strain BLS256)",
              },
              {
                value: 2,
                name: "Xanthomonas oryzae pv. oryzae (strain KACC10331 / KXO85)",
              },
              { value: 1, name: "Ectobacillus ponti" },
              { value: 1, name: "Pseudomonas aeruginosa" },
              { value: 1, name: "Pseudomonas sp. FDAARGOS_761" },
              { value: 1, name: "Pseudomonas aeruginosa PA14" },
              { value: 1, name: "Bacillus thuringiensis subsp. israelensise" },
              { value: 1, name: "Bacillus thuringiensis HD-771e" },
              { value: 1, name: "Bacillus thuringiensis HD-789" },
              { value: 1, name: "Bacillus sp. BB56-3" },
              { value: 1, name: "Bacillus sp. AY2-1" },
              { value: 1, name: "Marispirochaeta aestuarii" },
              { value: 1, name: "Bacillus subtilis" },
              { value: 1, name: "Bacillus velezensis" },
              { value: 1, name: "uncultured bacterium" },
            ],
          },
        ],
      };
      // 使用刚指定的配置项和数据显示图表。
      myChart.setOption(option);

      // 添加图例点击事件监听
      var demo=this;
      myChart.on("legendselectchanged", function (params) {
        // console.log("Selected legend name:", params.name);
        demo.list_flag=true;
        sessionStorage.setItem("bacteria_name", params.name);
        demo.componentKey += 1; // 改变key值强制刷新组件

      });
    },
  },
  created() {},
};
</script>