<template>
  <div class="home_div">
    <div class="text_box">
      <p>
        Melatonin plays a vital role in animal growth and development. In
        general, the life cycle of an animal includes growth, reproduction, and
        aging. And melatonin functions in these different life stages by
        regulating different genes. In addition, animals suffer from various
        diseases and injuries during growth and development, and melatonin also
        has great potential in the treatment of these diseases and hazards.
        Below, users can browse by stages of life cycle and related disease,
        respectively.
      </p>
    </div>

    <h2>
      <div class="title">Stages of life cycle</div>
    </h2>

    <img src="@/assets/imgs/functions_img/animal_growth.jpg" alt="" />

    <div class="fields-container">
      <div
        class="field-container"
        v-for="(field, index) in fields"
        :key="index"
      >
        <div class="field" @click="handleFieldClick(field.name)">
          {{ field.name }}
        </div>
        <!-- 图标 -->
        <img
          v-if="index < fields.length - 1"
          src="@/assets/imgs/functions_img/right.png"
          alt="arrow"
          class="icon"
        />
      </div>
    </div>

    <h2>
      <div class="title">Diseases and injuries</div>
    </h2>

    <div class="image-grid">
      <div
        class="row"
        v-for="(row, rowIndex) in paginatedImages"
        :key="rowIndex"
      >
        <div class="image-item" v-for="(item, colIndex) in row" :key="colIndex">
          <img
            :src="item.img_url"
            :alt="item.img_name"
            @click="disease_detail(item.img_name)"
          />
          <span>{{ item.img_name }}</span>
        </div>
      </div>
    </div>

    <el-pagination
      background
      layout="prev, pager, next, jumper"
      :total="img_pagination_params.total"
      :page-size="img_pagination_params.pageSize"
      :current-page.sync="img_pagination_params.page"
      @current-change="handlePageChange"
    />

    <div v-if="list_flag" style="margin-top: 40px" ref="results">
      <span class="header-title">Search results</span>
      <FunctionListData :Params="detail_pagination_params"></FunctionListData>
    </div>
  </div>
</template>


<script>
import FunctionListData from "@/components/FunctionListData.vue";

export default {
  components: { FunctionListData },
  data() {
    return {
      fields: [{ name: "Growth" }, { name: "Reproduction" }, { name: "Aging" }],
      img_pagination_params: {
        pageSize: 15,
        page: 1,
        total: 100,
      },
      detail_pagination_params: {},
      images: [],
      paginatedImages: [],
      list_flag: false,
    };
  },
  computed: {},
  methods: {
    async get_img_data() {
      await this.$http
        .get("disease", {
          params: {
            pageSize: this.img_pagination_params.pageSize,
            page: this.img_pagination_params.page,
          },
        })
        .then((res) => {
          this.img_pagination_params.total = res.data.total;
          this.images = res.data.data.map((item) => ({
            img_name: item[0],
            img_url: item[1],
          }));
          this.updatePaginatedImages(); // 分页展示
          // console.log(this.images);
        });
    },

    updatePaginatedImages() {
      const rows = [];
      for (let i = 0; i < this.images.length; i += 5) {
        rows.push(this.images.slice(i, i + 5)); // 每行取 5 个图片
      }
      this.paginatedImages = rows;
    },

    handlePageChange(newPage) {
      this.img_pagination_params.page = newPage;
      this.get_img_data(); // 获取新页数据
    },

    disease_detail(disease_name) {
      // console.log(disease_name);
      this.detail_pagination_params = {
        disease: disease_name,
      };

      this.list_flag = true;
      this.scrollToResults();
    },

    handleFieldClick(fieldName) {
      // console.log(`Field clicked: ${fieldName}`);
      this.detail_pagination_params = {
        animal_growth: fieldName,
      };
      this.list_flag = true;
      this.scrollToResults();
    },

    scrollToResults() {
      this.$nextTick(() => {
        const resultsElement = this.$refs.results;
        if (resultsElement) {
          resultsElement.scrollIntoView({ behavior: "smooth" });
        }
      });
    },
  },

  created() {
    this.get_img_data();
  },
};
</script>



<style  lang="less" scoped>
.home_div {
  max-width: 1580px;
  background-color: #ffffff;
  padding: 20px;

  border-radius: 8px;

}

.fields-container {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 10px;
}

.field-container {
  display: flex;
  align-items: center;
}

.field {
  padding: 10px 20px;
  border: 2px solid #7e7043;
  border-radius: 5px;
  font-weight: bold;
  text-align: center;
  background-color: #fff2cd;
  cursor: pointer;
}

.icon {
  width: 60px;
  height: 40px;
  margin: 0 10px; /* 为图标左右添加间距 */
}

.fields-container .field:last-child {
  margin-right: 0; /* 去掉最后一个字段的右边距 */
}

.text_box {
  padding: 8px;
  margin: 0 auto;

  border: 1px solid #ddd;
  border-radius: 10px;
}




p {
  font-size: 21px;
  color: black;
  margin-bottom: 5px;
  font-weight: 400;
}

em {
  font-style: italic;
  font-weight: bold;
  color: #0056b3;
}

.title {
  margin: 10px auto;
  height: 50px;
  width: 500px;
  border-bottom: 5px solid #1f4e79;
  text-align: center;
}

img {
  width: 100%;
}

.image-grid {
  margin-top: 20px;
}

.row {
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
}

.image-item {
  text-align: center;
  flex: 1;
  margin: 0 10px;
  cursor: pointer;
}

.image-item {
  transition: all 0.5s;
}
.image-item:hover {
  transform: scale(1.1); /* 让图片在悬停时放大 */
  // border: 2px solid;
}

.image-item img {
  height: 210px;
  width: 210px;
}

.image-item span {
  display: block;
  margin-top: 10px;
  font-size: 14px;
  color: #333;
}

.row {
  margin: 60px 5px;
  span {
    font-size: 16px;
    font-weight: 500;
  }
}

.header-title {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  background-color: rgb(31, 78, 121); /* 深蓝色背景 */
  color: white;
  font-size: 22px; /* 适当增加字体大小以匹配视觉效果 */
  font-weight: bold;
  padding: 10px 10px; /* 增加左右内边距 */
  width: 100%; /* 宽度设置为100% */
  box-sizing: border-box; /* 确保内边距和边框包含在宽度内 */
  margin-bottom: 15px;
}
</style>