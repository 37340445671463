<template>
  <div class="home_div">
    <el-card class="box-card">
      <div class="text_box">
        <h1>What is MAGEdb?</h1>
        <p>
          The
          <em>Melatonin Associated Gene Exploration database (MAGEdb) </em> was
          designed to serve researchers work on melatonin with a user-friendly
          interface as well as performance-efficient frame. The database
          contains 17,684 genes from 1926 species including animals, plants, and
          microorganisms. Among them: (1) We carefully examined 5,117
          literatures and reviewed public databases such as NCBI, Uniprot,
          Ensembl, etc., and finally obtained 8,949 genes associated with
          melatonin; (2) 8735 potential orthologues was identified in the
          Uniprot database accomplished using the reciprocal best hits strategy
          implemented; (3) We annotated the obtained genes, including gene and
          protein names, sequences, species, gene expression, protein structure,
          protein family and conserved domain, KEGG pathway, GO annotation, and
          protein-protein interaction; (4) The genes we collected were
          classified in detail, including by species, signaling pathways, gene
          function, etc.; (5) We have assembled a series of tools on the website
          to facilitate better analysis and research. The convenient web
          interface will make better use of melatonin-related genes. MAGEdb will
          be a useful resource for studying melatonin and help researchers
          further reveal the mechanism of action of melatonin at the molecular
          level.
        </p>
      </div>

      <div class="img_and_news">
        <div class="left-section">
          <div class="img_play">
            <h1 class="welcome">WELCOME TO MAGEdb</h1>
            <div class="carousel">
              <!-- 左侧切换按钮 -->
              <div class="arrow left-arrow" @click="nextImage">
                <i class="el-icon-arrow-left"></i>
              </div>

              <!-- 当前图片 -->
              <img
                :src="currentImage"
                alt="Carousel Image"
                class="carousel-image"
              />

              <!-- 右侧切换按钮 -->

              <div class="arrow right-arrow" @click="nextImage">
                <i class="el-icon-arrow-right"></i>
              </div>

              <!-- 图片指示器 -->
              <div class="carousel-indicators">
                <span
                  v-for="(image, index) in images"
                  :key="index"
                  :class="{ active: currentIndex === index }"
                  @click="goToImage(index)"
                ></span>
              </div>
            </div>
          </div>
        </div>
        <div class="right-section">
          <h2>
            <i class="el-icon-s-comment" style="font-size: 40px"></i> News &
            Events
          </h2>
          <ul>
            <li>MAGEdb version 1.0 was online on 2024-06-30!</li>
            <li>New contact information available! 2024-07-15</li>
            <li>
              The tool for gene function annotation was integrated into our
              database. 2024-08-09
            </li>
          </ul>
        </div>
      </div>

      <!-- 数量展示 -->
      <div class="num_count">
        <div class="Exp card">
          <h2>8949</h2>
          <p>Experimental Genes</p>
        </div>
        <div class="Put card">
          <h2>8735</h2>
          <p>Putative Genes</p>
        </div>
        <div class="Spe card">
          <h2>1926</h2>
          <p>Species</p>
        </div>
        <div class="Pla card">
          <h2>16</h2>
          <p>Plant Stress</p>
        </div>
        <div class="Dis card">
          <h2>82</h2>
          <p>Diseases and injuries</p>
        </div>
        <div class="Ref card">
          <h2>5117</h2>
          <p>References</p>
        </div>
      </div>

      <!-- external-links 部分 -->
      <div class="external-links-container">
        <h2 class="title-link">
          <i class="el-icon-s-order"></i> External Links
        </h2>

        <div class="border"></div>

        <!-- Cite Us Section -->
        <div class="section">
          <h3 class="section-title">Cite Us</h3>
          <p class="section-text">
            You may copy, distribute and transmit the data from MAGEdb for
            academic use and you may not use MAGEdb for commercial purposes.
          </p>
        </div>

        <!-- Contact Us Section -->
        <div class="section">
          <h3 class="section-title">Contact Us</h3>
          <p class="section-text">
            If you have any questions, comments or suggestions about our
            website, please feel free to contact us.
          </p>
          <p class="email">
            Email:
            <a href="mailto:gefei_hao@foxmail.com">gefei_hao@foxmail.com</a>
          </p>
        </div>
      </div>
    </el-card>
  </div>
</template>


<script>
export default {
  data() {
    return {
      images: [
        require("@/assets/imgs/home_img/1.png"), // 替换为实际图片路径
        require("@/assets/imgs/home_img/2.png"), // 替换为实际图片路径
        require("@/assets/imgs/home_img/3.png"), // 替换为实际图片路径
        require("@/assets/imgs/home_img/4.png"), // 替换为实际图片路径
        require("@/assets/imgs/home_img/5.png"), // 替换为实际图片路径
      ],
      currentIndex: 0,
    };
  },
  computed: {
    currentImage() {
      return this.images[this.currentIndex];
    },
  },
  methods: {
    goToImage(index) {
      this.currentIndex = index;
    },
    nextImage() {
      this.currentIndex = (this.currentIndex + 1) % this.images.length;
    },
    prevImage() {
      this.currentIndex =
        (this.currentIndex - 1 + this.images.length) % this.images.length;
    },
  },
  mounted() {
    setInterval(this.nextImage, 5000); // 每5秒自动切换图片
  },
};
</script>



<style scoped>
img {
  transition: all 0.5s;
}
img:hover {
  transform: scale(1.03); /* 让图片在悬停时放大 */
}

.img_and_news {
  display: flex;
  justify-content: space-between;
  margin: 20px 0px;
}

.left-section {
  flex: 1.5;
  padding-right: 10px;
}

.right-section {
  flex: 1;
  padding-left: 10px;
}

.right-section h2 {
  color: rgb(31, 78, 121);
  font-size: 24px;
  font-weight: bold;
  margin-bottom: 10px;
}

.right-section ul {
  list-style: none;
  padding: 0;
}

.right-section ul li {
  margin-bottom: 10px;
  font-size: 18px;
  font-weight: 500;
  color: #333;
}

.right-section ul li:before {
  content: "◆";
  color: black;
  margin-right: 8px;
  font-size: 34px;
}

.img_play {
  display: flex;
  flex-direction: column;
  align-items: center;
  border: 3px dashed rgb(31, 78, 121);
  padding: 10px;
  
}

.welcome {
  font-size: 24px;
  color: rgb(31, 78, 121);
  font-weight: bold;
  text-align: center;
  margin-bottom: 20px;
}

.carousel {
  position: relative;
  width: 100%;
  padding: 0px 15px;
}

.carousel-image {
  width: 900px;
  margin-left: 30px;
  border: 1px solid #ddd;
  border-radius: 10px;
}

.carousel-indicators {
  display: flex;
  justify-content: center;
  margin-top: 10px;
}

.carousel-indicators span {
  display: inline-block;
  width: 12px;
  height: 12px;
  margin: 0 5px;
  background-color: #ccc;
  border-radius: 50%;
  cursor: pointer;
}

.carousel-indicators span.active {
  background-color: #0056b3;
}

.arrow {
  position: absolute;
  top: 40%;
  transform: translateY(-50%);
  /* background-color: rgba(0, 0, 0, 0.3); */
  border: none;
  color: #0056b3;
  font-size: 44px;
  padding: 10px;
  cursor: pointer;
  border-radius: 50%;
  user-select: none;
}

.left-arrow {
  left: -10px;
}

.right-arrow {
  right: -15px;
}

.home_div {
  max-width: 1580px;

  padding: 20px;
  background-color: #f5f5f5;
  border-radius: 8px;
}

.text_box {
  padding: 8px;
  margin: 0 auto;
  background-color: #f9f9f9;
  border: 1px solid #ddd;
  border-radius: 10px;
}

h1 {
  font-size: 20px;
  color: #1f2d3d;
  text-align: center;
  font-weight: bold;
}

p {
  font-weight: 500;
  text-indent: 2em;
  font-size: 16px;

  color: #333;
  line-height: 1.7;
}

em {
  font-style: italic;
  font-weight: bold;
  color: #0056b3;
}

.num_count {
  height: 150px;
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
}
.num_count div {
  width: 150px;
  height: 125px;

  margin-top: 25px;
}
.Exp {
  background-color: rgb(132, 60, 12);
}
.Put {
  background-color: rgb(31, 78, 121);
}
.Spe {
  background-color: rgb(56, 87, 35);
}
.Pla {
  background-color: rgb(193, 98, 0);
}
.Dis {
  background-color: rgb(55, 147, 146);
}
.Ref {
  background-color: rgb(140, 145, 123);
}
.card {
  text-align: center;
  color: white;
}
/* 数字部分的样式 */
.card h2 {
  font-size: 24px;
  font-weight: 600;
  height: 10px;
}

/* 描述文本的样式 */
.card p {
  font-size: 20px;
  font-weight: 500;
  margin: 2px 10px;
  color: white;
  text-indent: 0em;
  padding: 2px 5px;
}

/* External Links */

/* 整体容器样式 */
.external-links-container {
  width: 100%; /* 父容器的固定宽度 */
  padding: 10px;
  background-color: #f9f9f9;
  margin: 5px auto; /* 居中对齐 */
}

/* 标题样式 */
.title-link {
  font-size: 26px;
  color: rgb(31, 78, 121);
  font-weight: bold;
  margin-bottom: 10px;
}

/* 每个部分的样式 */
.section {
  margin-bottom: 1px;
  padding: 5px;
}

/* 小标题样式 */
.section-title {
  font-size: 24px;
  color: rgb(31, 78, 121);
  border-left: 6px solid rgb(31, 78, 121);
  padding-left: 10px;
  font-weight: bold;
}

/* 文本样式 */
.section-text {
  font-size: 19px;
  color: black;
  line-height: 1.8;
  margin-bottom: 5px;
  font-weight: 500;
}

/* 邮件链接样式 */
.email {
  font-size: 20px;
}

.email a {
  color: black;
  text-decoration: none;
}
.border {
  width: 1475px;
  height: 10px;
  background-color: rgb(127, 127, 127);
}
</style>