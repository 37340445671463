<template>
  <div class="home_div">
    <h2>
      <div class="title">Plant growth and development</div>
    </h2>
    <div class="content">
      <img
        src="@/assets/imgs/functions_img/plant_growth.jpeg"
        alt="Plant growth and development"
        class="plant_growth_img"
      />
      <div class="words">
        <div v-for="(word, index) in words" :key="index" class="word-item">
          <img
            src="@/assets/imgs/functions_img/plant_right.png"
            alt="Arrow"
            class="arrow-icon"
          />
          <span style="cursor: pointer" @click="get_plant_growth(word)">{{
            word
          }}</span>
        </div>
      </div>
    </div>
    <h2>
      <div class="title">Abiotic stress</div>
    </h2>
    <div class="image-grid abiotic">
      <div
        class="image-item"
        v-for="(item, colIndex) in abiotic_images"
        :key="colIndex"
      >
        <img :src="item[1]" :alt="item[0]" @click="get_stress(item[0])" />
        <span>{{ item[0] }}</span>
      </div>
    </div>

    <h2>
      <div class="title">Biotic stress</div>
    </h2>
    <div class="image-grid abiotic">
      <div
        class="image-item"
        v-for="(item, colIndex) in biotic_images"
        :key="colIndex"
      >
        <img :src="item[1]" :alt="item[0]" @click="get_stress(item[0])" />
        <span>{{ item[0] }}</span>
      </div>
    </div>

    <div v-if="list_flag" style="margin-top: 40px" ref="results">
      <span class="header-title">Search results</span>
      <FunctionListData :Params="params"></FunctionListData>
    </div>
  </div>
</template>



<script>
import FunctionListData from "@/components/FunctionListData.vue";

export default {
  components: { FunctionListData },
  data() {
    return {
      words: ["Flower", "Leaf", "Fruit", "Stem", "Seed", "Shoot", "Root"],
      selectedWord: "",
      params: {},
      abiotic_images: [],
      biotic_images: [],
      list_flag: false,
    };
  },
  computed: {},
  methods: {
    plant_growth(word) {
      console.log(word);
    },
    async get_img_data() {
      await this.$http.get("stress").then((res) => {
        this.abiotic_images = res.data.Abiotic_stress;
        this.biotic_images = res.data.Biotic;
        // console.log(this.images);
        console.log(this.abiotic_images);
      });
    },

    get_plant_growth(plant_growth) {
      this.params = {
        plant_growth,
      };
      this.list_flag = true;
      this.scrollToResults();
    },

    get_stress(stress) {
      this.params = {
        stress,
      };
      this.list_flag = true;
      this.scrollToResults();
    },

    scrollToResults() {
      this.$nextTick(() => {
        const resultsElement = this.$refs.results;
        if (resultsElement) {
          resultsElement.scrollIntoView({ behavior: "smooth" });
        }
      });
    },
  },

  created() {
    this.get_img_data();
  },
};
</script>



<style scoped>
.home_div {
  max-width: 1580px;
  background-color: #ffffff;
  padding: 20px;

  border-radius: 8px;
  align-items: center;
}

.content {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
}

.plant_growth_img {
  max-width: 400px;
  height: auto;
  margin-right: 30px;
}

.words {
  display: flex;
  flex-direction: column;
}

.word-item {
  display: flex;
  align-items: center;
  margin: 5px 0;
}

.arrow-icon {
  width: 250px;
  margin-right: 10px;
  margin-top: 5px;
}

.image-grid {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
}

.image-grid.abiotic,
.image-grid.biotic {
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;
  width: 100%;
}

.image-grid.abiotic img {
  flex: 1 1 20%; /* For two rows adjust the percentage accordingly */
  margin: 5px;
}

.image-grid.biotic img {
  flex: 1 1 100%; /* Each image will take full row */
  margin: 5px;
}

.image-item {
  text-align: center;
  flex: 1;
  margin: 0 10px;
  cursor: pointer;
}

.image-item {
  transition: all 0.5s;
}
.image-item:hover {
  transform: scale(1.1); /* 让图片在悬停时放大 */
}

.image-item img {
  height: 210px;
  width: 210px;
}

.image-item span {
  display: block;
  margin-top: 10px;
  font-size: 16px;
  font-weight: 500;
  color: #333;
}

.title {
  margin: 10px auto;
  height: 50px;
  width: 500px;
  border-bottom: 5px solid #1f4e79;
  text-align: center;
}

.header-title {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  background-color: rgb(31, 78, 121); /* 深蓝色背景 */
  color: white;
  font-size: 22px; /* 适当增加字体大小以匹配视觉效果 */
  font-weight: bold;
  padding: 10px 10px; /* 增加左右内边距 */
  width: 100%; /* 宽度设置为100% */
  box-sizing: border-box; /* 确保内边距和边框包含在宽度内 */
  margin-bottom: 15px;
}
</style>
